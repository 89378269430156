<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="privacy">
        <i class="el-icon-arrow-left" @click="dianji_fanhui"></i>
        <h1>CBR智能经营数据模型用户隐私保护协议</h1>
        <p>（以下简称“本协议”）</p>
        <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;欢迎使用CBR官网及服务！
            为使用CBR官网（以下简称“本官网”）及服务，请用户务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款，以及开通或使用某项服务的单独协议，并选择接受或不接受。限制、免责条款可能以加粗形式提示注意。
            除非用户已阅读并接受本协议所有条款，否则用户无权下载或使用本官网相关服务。用户的下载、使用、登录等行为即视为已阅读并同意上述的约束。
            <br />
            <br />
            1.本协议的范围
            <br />
            1.1 本协议适用主体范围： 本协议是您作为用户，与CBR智能经营数据模型团队（以下简称“CBR”）之间关于下载、使用本官网，以及使用CBR相关服务所订立的协议。
            <br />
            1.2 协议关系及冲突条款: 本协议内容包括CBR可能不断发布的关于本服务的相关协议、业务规则等内容。上述内容一经正式发布，即为本协议不可分割的组成部分，用户同样应当遵守。
            <br />
            <br />

            2. 关于本服务
            <br />
            2.1 本服务的内容 
            <br />
            本服务内容是指CBR通过互联网向用户提供的本官网信息使用权，同时为用户提供本官网内容的阅读、下载和在线咨询服务。
            <br />
            2.2 本服务的形式
            <br />
            2.2.1 用户使用本官网需要登陆、注册、填写其他表单，就CBR官网给予用户一项不可转让及非排他性的许可。用户仅可为访问或使用本官网的目的而使用这些内容及服务。
            <br />
            2.2.2 本服务中软件提供包括但不限于移动端和PC端等多个应用版本，用户应选择与所使用环境相相匹配的网站版本。
            <br />
            2.3 本服务的范围
            <br />
            2.3.1 本条及本协议其他条款未明示授权的其他一切权利仍由CBR保留，用户在行使这些权利时须另外取得CBR的书面许可。CBR如果未行使前述任何权利，并不构成对该权利的放弃。
            <br />
            2.3.2 如果用户停止使用本官网，或服务被终止或取消，本公司可以从服务器删除您的数据。服务停止、终止或取消后，本公司没有义务向用户返还任何数据。
            <br />
            <br />

            3. 资料的获取
            <br />
            3.1 用户可以直接从CBR的网站上获取资料。
            <br />
            3.2 如果用户从未经CBR授权的第三方获取本官网或与本官网名称相同的资料，CBR无法保证该资料内容正确，并对因此给用户造成的损失不予负责。
            <br />
            <br />

            4. 资料的下载
            <br />
            4.1 CBR可能为不同的用户提供不同的内容展现形式，用户应当根据实际情况选择下载或阅读合适的资料。
            <br />
            4.2 下载资料后，用户应按照相应类型的资料选择程序打开阅读。
            <br />
            4.3 为提供加优质、安全的服务，在本网站打开时CBR可能推荐用户安装其他版本浏览器，用户可以选择安装或不安装。
            <br />
            <br />

            5. 官网更新
            <br />
            5.1 为了改善用户体验、完善服务内容，CBR将不断努力开发新的服务，并为用户不时提供本官网的更新（这些新可能会采取替换、修改、功能强化、版本升级等形式）。
            <br />
            5.2 为了保证本官网及服务的安全性和功能的一致性，CBR有权不经向用户特别通知而对本官网进行更新，或者对本官网的部分功能效果进行改变或限制。
            <br />
            5.3 本官网新版本发布后，本公司将可能视情况设定一定期限的过渡期，以方便用户使用新版本。过渡期结束后，旧版本的官网可能无法使用。CBR不保证旧版本官网继续可用及相应的客户服务，请核对并使用新版本。
            <br />
            <br />

            6. 隐私声明
            <br />
            6.1 用户信息收集
            <br />
            6.1.1 用户在注册帐号或使用本官网的过程中，需要提供一些必要的信息，例如：真实姓名、地址、籍贯、企业名称、行业、规模、职位、所在部门、电话号码和电子邮件地址等。上述信息视为您的身份识别信息，本公司用这些信息来管理您的账户，并为您提供一系列服务的访问。本公司也可能利用这些信息来通知您新的产品和市场活动。您可以在本公司取得账户信息和密码，并登录查看及修改账户设置和相关信息。
            <br />
            6.1.2 您只能通过您的密码来使用您的帐号。如果您泄漏了密码，您可能丢失了您的个人识别信息，并且可能出现对您不利的后果。因此，无论任何原因危及您的密码安全，您应该通过本公司预留的联系方式和本公司取得联系。
            <br />
            6.1.3 您成功注册后，本公司将收集您的官网浏览行为，作为您的身份识别信息的一部分，本公司用这些信息可能为您提供一系列的服务，以方便您更好的使用本网站。
            <br />
            6.1.4 本公司还可能收集并存储以下数据：您在本官网中的标识符、经度和纬度、性别、年龄、用户触发的事件、错误和页面浏览量，还可能包含您的IP地址、设备类型、地区等信息。
            <br />
            6.2 用户信息的披露和使用
            <br />
            6.2.1 本公司将通过您对本网站的行为来收集相关信息，并以此为您提供更加深入的支持与服务。
            <br />
            6.2.2 本公司不会向任何无关第三方提供，出售，出租，分享和交易用户的个人信息，但为方便您使用本公司服务，本公司将对您及（或）您的公司的身份和交易数据进行综合统计，本公司不会披露任何可能用以识别用户的个人身份的资料，但从用户的用户名或其它可披露资料分析得出的资料不受此限。
            <br />
            6.2.3 您同意本公司可披露或使用您的个人信息以用于识别和（或）确认您的身份，或解决争议，或有助于确保网站安全，限制欺诈、非法或其他刑事犯罪活动。
            <br />
            6.2.4 您同意本公司可披露或使用您的个人信息以保护您的生命、财产之安全或为防止严重侵害他人之合法权益或为公共利益之需要。
            <br />
            6.2.5 您同意本公司可披露或使用您的个人信息以改进本公司的服务，并使本公司的服务能符合您的要求，从而使您在使用本公司服务时得到好的使用体验。
            <br />
            6.2.6 您同意本公司利用您的个人信息与您联络，并向您提供您可能感兴趣的信息，如：介绍产品、服务、促销优惠或者商业投资机会的商业性短信息等，您接受本条款中的隐私声明即为明示同意收取这些信息。
            <br />
            6.2.7 当本公司被法律强制或依照政府或依权利人因识别涉嫌侵权行为人的要求而提供您的信息时，本公司将善意地披露您的资料。
            <br />
            6.2.8 当本公司需要保护本公司、本公司的代理人、客户、用户和其他人的权益和财产，包括执行本公司的协议、政策和使用条款时，本公司将善意的披露您的资料。
            <br />
            6.2.9 您同意如果本公司拟进行企业并购、重组、出售全部或部分股份和/或资产时，本公司有权在与前述交易的相关方签署保密协议的前提下向其披露您的资料以协助本公司完成该等交易。
            <br />
            6.2.10 您在此同意，在您使用本官网期间，本公司可以在对您的原始数据、进行信息脱密处理的情况下，将该等信息用于制作及发布行业分析报告、行业交流等非营利性用途。
            <br />
            6.3 用户信息的存储和交换
            <br />
            6.3.1 本公司所收集的用户信息将保存在本公司的服务器或本公司的合作产品提供商的服务器上。本公司将根据本条款中的隐私声明保存收集的用户信息，除非适用法律要求或允许保存长时间，本公司将在本条款中的隐私声明规定的目的实现后的合理期限内删除本公司所收集的用户信息。之后，如果为本条款中的隐私声明规定的目的不再需要，本公司可能在合理时间内完全删除本公司所保存的用户信息。本公司不去核实试用用户信息是否正确。尽管有上述规定，本公司仍可能保存某些解决争议、满足技术和法律要求和维护本公司的服务的安全完整运行所需的试用用户信息。
            <br />
            6.4 用户信息安全
            <br />
            6.4.1 本公司有相应的安全措施来确保本官网收集的用户信息不丢失，不被滥用和变造。这些安全措施包括但不限于向其它服务器备份数据和对用户密码加密。尽管本公司有这些安全措施，但请注意在因特网上不存在“完善的安全措施”，因此用户信息可能非因本公司的原因而丢失，包括但不限于他人非法利用用户资料，用户下载安装的其他软件或访问的其他网站中可能含有病毒、木马程序或其他恶意程序，威胁用户的终端设备信息和数据安全，继而影响本官网的正常使用等。对此，本公司不承担任何责任。
            <br />
            6.5 用户对本软件分析结果的使用
            <br />
            6.5.1 用户在此无条件同意，用户在免费使用本公司提供的服务期间，本条款中的隐私声明项下的信息及对该等信息的分析结果的所有权由本公司与用户共同拥有。本公司建议，用户应当以符合相关法律规定和道德义务的方式使用该等信息。同时，本公司提醒注意，因包括但不限于技术原因、网络传输质量等原因，导致本公司对本公司收集的信息的分析结果可能存在不准确的情况，对于该等不准确所导致的问题或者损失，本公司不承担任何责任。如果您接受本条款中的隐私声明并把本公司提供给您的信息、资料集成到您的网站或者应用中，您已经同意并向本公司保证您所有的终用户同意本公司收集、使用并分析其信息，并且遵守本条款中的隐私声明的全部规定。
            <br />
            6.5.2 您在此进一步保证，您不会因为本公司按照本声明的规定收集、使用、分析、披露收集的信息及对该等信息的分析结果而对本公司产生任何形式的诉求、投诉等。如果您因本条款中的隐私声明项下的对其信息的收集、使用、披露或者对该等信息的分析以及您对分析结果的使用和处分而致使本公司遭受任何形式的诉求以及投诉等，您将负责全面给予解决；如果导致本公司发生任何形式的损失，您将负责给予本公司赔偿。
            <br />
            <br />
            7. 用户行为规范
            <br />
            7.1 本官网使用规范。除非法律允许或本公司书面许可，用户使用本官网过程中不得从事下列行为：
            <br />
            7.1.1 删除本网站关于著作权的信息；
            <br />
            7.1.2 对本网站进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本软件的源代码；
            <br />
            7.1.3 对本公司拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；
            <br />
            7.1.4 对本网站或者本网站运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及本软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经本公司授权的第三方工具/服务接入本软件和相关系统；
            <br />
            7.1.5 通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；
            <br />
            7.1.6 通过非本公司开发、授权的第三方软件、插件、外挂、系统，登录或使用本公司软件及服务，或制作、发布、传播上述工具；
            <br />
            7.1.7 自行或者授权他人、第三方软件对本软件及其组件、模块、数据进行干扰；
            <br />
            7.2 用户理解并同意，基于用户体验或相关服务平台运营安全、平台规则要求及健康发展等综合因素，针对以下情形，本公司有权视具体情况中止或终止提供本服务，包括但不限于：
            <br />
            7.2.1 违反法律法规或本协议规定的；
            <br />
            7.2.2 影响服务体验的；
            <br />
            7.2.3 存在安全隐患的。
            <br />
            7.3 违约处理
            <br />
            7.3.1 如果本公司发现或收到他人举报或投诉您违反本协议约定的，本公司有权视行为情节对您处以包括但不限于警告、限制或禁止使用部分或全部功能、帐号封禁直至注销的处罚。
            <br />
            7.3.2 您理解并同意，本公司有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，您应独自承担由此而产生的一切法律责任。
            <br />
            7.3.3 您理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，用户应当独立承担责任；本公司因此遭受损失的，您也应当一并赔偿。
            <br />
            <br />
            8. 知识产权声明
            <br />
            8.1 CBR是本官网的知识产权权利人。本官网的一切著作权、商标权、专利权、商业秘密等知识产权，均受中华人民共和国法律法规和相应的国际条约保护，本公司享有上述知识产权，但相关权利人依照法律规定和本条款应享有的权利除外。
            <br />
            8.2 未经本公司或相关权利人书面同意，用户不得为任何商业或非商业目的自行或许可任何第三方实施、利用、转让上述知识产权。
            <br />
            <br />
            9. 第三方软件或技术
            <br />
            9.1 本官网可能会使用第三方软件或技术（包括本软件可能使用的开源代码和公共领域代码等，下同），这种使用已经获得合法授权。
            <br />
            9.2 本官网如果使用了第三方的软件或技术，本公司将按照相关法规或约定，对相关的协议或其他文件，可能通过本协议附件、在本官网进行展示，它们可能会以“软件使用许可协议”、“授权协议”、“开源代码许可证”或其他形式来表达。前述通过各种形式展现的相关协议、其他文件及网页，均是本协议不可分割的组成部分，与本协议具有同等的法律效力，您应当遵守这些要求。如果您没有遵守这些要求，该第三方或者国家机关可能会对您提起诉讼、罚款或采取其他制裁措施，并要求本公司给予协助，您对此应当自行承担法律责任。
            <br />
            9.3 如因本官网使用的第三方软件或技术引发的任何纠纷，应由该第三方负责解决，本公司不承担任何责任。本公司不对第三方软件或技术提供客服支持，若用户需要获取支持，请与第三方联系。
            <br />
            <br />
            10. 其他
            <br />
            10.1 用户使用本官网即视为您已阅读并同意接受本协议的约束。本公司有权在必要时修改本协议条款。用户可以在本软件的新版本中查阅相关协议条款。本协议条款变更后，如果用户继续使用本官网，即视为用户已接受修改后的协议。如果用户不接受修改后的协议，应当停止使用本软件。
            <br />
            10.2 本协议签订地为中华人民共和国北京市。
            <br />
            10.3 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。
            <br />
            10.4 若用户和CBR之间发生任何纠纷或争议，应友好协商解决；协商不成的，用户同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。
            <br />
            10.5 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
            <br />
            10.6 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
            <br />
        </p>
    </div>
</template>

<script>
export default {
  name: 'privacy',
  data () {
    return {

    }
  },
  created () {

  },
  mounted () {
  },
  watch: {
        
  },
  methods: {
    dianji_fanhui () {
      this.$router.go(-1)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.privacy{
    // min-height: 100vh;
	background: #fff;
    padding:1rem 0;
    position: relative;
    i{
        position: absolute;
        font-size: 0.2rem;
        padding: 0.03rem 0.08rem;
        border:0.01rem solid #ccc;
        cursor: pointer;
        left:0.9rem;
        top:0.9rem;
        &:hover{
            border:0.01rem solid rgb(139, 209, 255);
            color:rgb(139, 209, 255);
        }
    }
    h1{
        font-size: 0.26rem;
        text-align: center;
    }
    p{
        &:nth-child(3){
            font-size: 0.2rem;
            text-align: center;
        }
        &:nth-child(4){
            width: 90%;
            margin: 0 auto;
            font-size: 0.18rem;
        }
    }
}
</style>
